import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DetailPanelView, YesNoDialog } from "../../../common";
import {
  ContractorPersonalInfoInputs,
  ContractorPersonalInfoSchema,
  prefillContractorPersonalInfoInputs,
  resetRadioButtons,
} from "./PersonalInfoDetail";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ContractorDetailTabs from "./ContractorDetailTabs";
import * as yup from "yup";
import {
  ContractorDetailResponseType,
  getDetailOfContractor,
  getSecondaryCompanyCounter,
  postNewContractor,
  putUpdateContractor,
  putUpdateContractorEntrances,
  putUpdateContractorVaccination,
  putUpdateContractorVaccinationAndEntrances,
} from "../redux/apiCalls";
import { isNil } from "ramda";
import {
  ContractorQualificationsInputs,
  prefillContractorQualificationsInputs,
  QualificationsSchema,
} from "./QualificationsDetail";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../appStore";
import contractorReducer from "../redux/reducer";
import contractorSelectors from "../redux/selectors";
import moment from "moment";
import axios, { CancelTokenSource } from "axios";
import { CounterResponseItem } from "../../../services/apiServiceTypes";
import { ROLES, USER_PERMISSIONS } from "../../../auth/constants";
import { useAuthorization } from "../../../auth";
import { Box } from "@material-ui/core";

export interface ContractorInputs
  extends ContractorPersonalInfoInputs,
    ContractorQualificationsInputs {}

export interface ContractorDetailSectionProps {
  contractorId?: string;
  setSelectedContractorId: (value: string | undefined) => void;
  newContractorMode?: boolean;
  refetchContractors: () => void;
  setNewContractorMode: (value: boolean) => void;
}

const ContractorDetailSection: React.FC<ContractorDetailSectionProps> = ({
  contractorId,
  setSelectedContractorId,
  newContractorMode,
  refetchContractors,
  setNewContractorMode,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [newProfilePhoto, setNewProfilePhoto] = useState<Blob | null>(null);
  const [newCertificationCard, setNewCertificationCard] = useState<Blob | null>(
    null
  );
  const [data, setData] = useState<ContractorDetailResponseType>();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const previousContractorId = useRef<string>();
  const selectedEntrances = useSelector(
    contractorSelectors.getSelectedEntrances
  );
  const otherCertifications = useSelector(
    contractorSelectors.getOtherCertifications
  );
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const [otherCertificationErrors, setOtherCertificationErrors] =
    useState<any>();
  const [alertMessage, setAlertMessage] = useState<string>();
  const contractorDetailRequest = useRef<CancelTokenSource>(
    axios.CancelToken.source()
  );

  const { isAllowed: canEditContractor } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.CONTRACTORS_EDIT]
  );

  const { isAllowed: canAddContractor } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.CONTRACTORS_ADD]
  );

  const { isAllowed: canVerifyVaccination } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.CONTRACTORS_VERIFY_VACCINATION]
  );

  const { isAllowed: canAssignEntrances } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.CONTRACTORS_ENTRANCES_ASSIGNMENT]
  );

  const isEditable = useMemo(
    () =>
      (canAddContractor && newContractorMode) ||
      (canEditContractor && !newContractorMode),
    [canAddContractor, canEditContractor, newContractorMode]
  );

  const ContractorSchema = isEditable
    ? yup
        .object()
        .concat(ContractorPersonalInfoSchema)
        .concat(QualificationsSchema)
        .shape({})
    : yup.object().shape({});

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    errors,
    clearErrors,
    setError,
    watch,
  } = useForm<ContractorInputs>({
    resolver: yupResolver(ContractorSchema),
  });

  // TODO fix error caused by switching between controlled and uncontrolled component
  useEffect(() => {
    if (isNil(watch("Company")) || watch("Company") === "") {
      // setValue("SecondaryCompany", '');
      dispatch(contractorReducer.actions.setSecondaryCompanyList([]));
    } else {
      dispatch(getSecondaryCompanyCounter(watch("Company")))
        .then((list) => {
          setValue("SecondaryCompany", "");
          dispatch(contractorReducer.actions.setSecondaryCompanyName(""));
          dispatch(
            contractorReducer.actions.setSecondaryCompanyList(list.data ?? [])
          );
          setValue("SecondaryCompany", data?.secondaryCompany ?? "");
          if (list.data && data?.secondaryCompany) {
            const listData: Array<CounterResponseItem> = list.data;
            // eslint-disable-next-line eqeqeq
            const name: string =
              listData.find((item) => item.codeId === data?.secondaryCompany)
                ?.displayName ?? "";
            dispatch(contractorReducer.actions.setSecondaryCompanyName(name));
          }
        })
        .catch(() => {
          dispatch(contractorReducer.actions.setSecondaryCompanyList([]));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("Company"), dispatch, data]);

  const [openYesNoDialog, setOpenYesNoDialog] = React.useState<{
    isOpen: boolean;
    formInputs?: ContractorInputs;
  }>({ isOpen: false });

  const prefillInputsByData = useCallback(
    (newData: ContractorDetailResponseType) => {
      if (newData) {
        setData(newData);
        prefillContractorPersonalInfoInputs(setValue, newData);
        prefillContractorQualificationsInputs(setValue, newData);
        dispatch(
          contractorReducer.actions.setOtherCertifications(
            newData?.otherCertifications || []
          )
        );
        previousContractorId.current = contractorId;
      }
      // reset new profile photo
      dispatch(contractorReducer.actions.setNewProfilePhotoId(undefined));
    },
    [contractorId, dispatch, setValue]
  );

  const resetInputs = useCallback(() => {
    resetRadioButtons(setValue);
    reset();
    clearErrors();
    setAlertMessage(undefined);
    setNewCertificationCard(null);
    setNewProfilePhoto(null);
    setOtherCertificationErrors(undefined);
    setData(undefined);
    setSelectedTab(0);
    dispatch(contractorReducer.actions.setOtherCertifications([]));
  }, [clearErrors, dispatch, reset, setValue]);

  const fetchContractorDetail = useCallback(
    (userId: string) => {
      if (isLoadingData) {
        contractorDetailRequest.current.cancel(
          "Canceled previous detail of contractor"
        );
        contractorDetailRequest.current = axios.CancelToken.source();
      }
      setIsLoadingData(true);
      dispatch(
        getDetailOfContractor(userId, contractorDetailRequest.current.token)
      )
        .then((response) => {
          prefillInputsByData(response.data);
        })
        .catch(() => {
          resetInputs();
        })
        .finally(() => {
          setIsLoadingData(false);
        });
    },
    [dispatch, isLoadingData, prefillInputsByData, resetInputs]
  );

  useEffect(() => {
    if (newContractorMode) {
      resetInputs();
      previousContractorId.current = undefined;
    }

    if (
      contractorId &&
      !isLoadingData &&
      ((previousContractorId.current === undefined && !data) ||
        (previousContractorId.current !== contractorId && data))
    ) {
      resetInputs();
      fetchContractorDetail(contractorId);
    }
  }, [
    contractorId,
    data,
    fetchContractorDetail,
    isLoadingData,
    newContractorMode,
    resetInputs,
    previousContractorId,
  ]);

  const refreshContractorDetail = (userId: string) => {
    refetchContractors();
    setSelectedContractorId(userId);
    fetchContractorDetail(userId);
    setNewContractorMode(false);
    previousContractorId.current = undefined;
    dispatch(contractorReducer.actions.setNewProfilePhotoId(undefined));
    setSelectedTab(0);
  };

  const saveContractorDetail = (formInputData: ContractorInputs) => {
    if (isEditable) {
      processContractor(formInputData);
    } else if (!isEditable && canVerifyVaccination && !canAssignEntrances) {
      saveVaccinationAndEntrancesData(formInputData, true, false);
    } else if (!isEditable && canAssignEntrances && !canVerifyVaccination) {
      saveVaccinationAndEntrancesData(formInputData, false, true);
    } else if (!isEditable && canVerifyVaccination && canAssignEntrances) {
      saveVaccinationAndEntrancesData(formInputData, true, true);
    }
  };

  const saveVaccinationAndEntrancesData = (
    formInputData: ContractorInputs,
    saveVaccination: boolean,
    saveEntrances: boolean
  ) => {
    // only execute if existing user is selected and at least one of vaccination or entrances data should be saved
    if (data?.userId && (saveVaccination || saveEntrances)) {
      const formData = new FormData();
      formData.append("UserId", data.userId);

      if (saveVaccination) {
        formData.append(
          "VaccinationVerified",
          (formInputData.VaccinationVerified === true).toString()
        );

        if (
          !isNil(formInputData.VaccinationVerificationDate) &&
          formInputData.VaccinationVerificationDate !== ""
        ) {
          formData.append(
            "VaccinationVerificationDate",
            moment(formInputData.VaccinationVerificationDate)
              .utc(true)
              .toISOString()
          );
        }
      }

      if (saveEntrances) {
        selectedEntrances.forEach((entrance) => {
          formData.append("Entrances", entrance);
        });
      }

      setOtherCertificationErrors(undefined);
      setAlertMessage(undefined);

      const saveAction =
        saveVaccination && saveEntrances
          ? putUpdateContractorVaccinationAndEntrances
          : saveVaccination
          ? putUpdateContractorVaccination
          : putUpdateContractorEntrances;

      dispatch(saveAction(formData))
        .then(() => {
          refreshContractorDetail(data.userId);
        })
        .catch((error) => {
          if (
            error.response == null ||
            typeof error.response?.data === "string" ||
            error.response?.data instanceof String
          ) {
            if (error.response == null) {
              setAlertMessage("Failed to connect.");
            } else {
              setAlertMessage(error.response.data);
            }
            setSelectedTab(0);
          } else if (error.response.data.errors) {
            let newErrorObject: any = {};
            Object.keys(error.response.data.errors).forEach((key) => {
              if (error.response.data.errors[key].length > 0) {
                setError(key as keyof ContractorInputs, {
                  message: error.response.data.errors[key],
                });
                newErrorObject[key] = {
                  message: error.response.data.errors[key],
                };
              }
            });

            setOtherCertificationErrors(
              Object.keys(newErrorObject).length > 0
                ? newErrorObject
                : undefined
            );

            // In case when errors are only in Qualification tab open it
            if (
              !error.response.data.errors.hasOwnProperty(
                "VaccinationVerified"
              ) &&
              (error.response.data.errors.hasOwnProperty(
                "VaccinationVerificationDate"
              ) ||
                Object.keys(newErrorObject).length > 0)
            ) {
              setSelectedTab(1);
            } else if (
              Object.keys(error.response.data.errors).length > 0 &&
              selectedTab !== 0
            ) {
              setSelectedTab(0);
            }
          }
        });
    }
  };

  const processContractor = (formInputData: ContractorInputs) => {
    const promises = [];
    const formData = new FormData();
    formData.append("Title", formInputData.Title);
    formData.append("FirstName", formInputData.FirstName);
    formData.append("LastName", formInputData.LastName);
    formData.append("PhoneNumber", formInputData.PhoneNumber);
    formData.append("Email", formInputData.Email);
    formData.append("UnionMember", formInputData.UnionMember.toString());
    formData.append(
      "VaccinationVerified",
      (formInputData.VaccinationVerified === true).toString()
    );
    formData.append("BeaconScannerCard", formInputData.BeaconScannerCard);
    formData.append("Company", formInputData.Company);
    formData.append("SecondaryCompany", formInputData.SecondaryCompany);
    formData.append(
      "ValidFrom",
      moment(formInputData.ValidFrom).toISOString()
    );
    formData.append(
      "ValidUntil",
      moment(formInputData.ValidUntil).toISOString()
    );

    if (
      !isNil(formInputData.OshaCertificationFrom) &&
      formInputData.OshaCertificationFrom !== ""
    ) {
      formData.append(
        "OshaCertificationFrom",
        moment(formInputData.OshaCertificationFrom).utc(true).toISOString()
      );
    }
    if (
      !isNil(formInputData.OshaCertificationTo) &&
      formInputData.OshaCertificationTo !== ""
    ) {
      formData.append(
        "OshaCertificationTo",
        moment(formInputData.OshaCertificationTo).utc(true).toISOString()
      );
    }

    if (
      !isNil(formInputData.VaccinationVerificationDate) &&
      formInputData.VaccinationVerificationDate !== ""
    ) {
      formData.append(
        "VaccinationVerificationDate",
        moment(formInputData.VaccinationVerificationDate)
          .utc(true)
          .toISOString()
      );
    }

    otherCertifications.forEach((cert, index) => {
      formData.append(`OtherCertifications[${index}].Name`, cert.name);
      formData.append(
        `OtherCertifications[${index}].ValidTo`,
        moment(cert.validTo).utc(true).toISOString()
      );
      if (cert.id) {
        formData.append(`OtherCertifications[${index}].Id`, cert.id);
      }
    });

    selectedEntrances.forEach((entrance) => {
      formData.append("Entrances", entrance);
    });

    formData.append("SecondaryCompany", formInputData.SecondaryCompany);
    formData.append("SiteSafetyNumber", formInputData.SiteSafetyNumber);

    if (!isNil(newProfilePhoto)) {
      formData.append("NewProfilePhoto", newProfilePhoto);
    }

    if (!isNil(newCertificationCard)) {
      formData.append("NewCertificationCardPhotos", newCertificationCard);
    }

    if (newContractorMode) {
      promises.push(
        dispatch(postNewContractor<{ userId: string }>(formData)).then(
          (response) => {
            refreshContractorDetail(response.data.userId);
          }
        )
      );
    } else if (contractorId && data?.userId) {
      formData.append("UserId", data.userId);
      promises.push(
        dispatch(putUpdateContractor(formData)).then(() => {
          refreshContractorDetail(data.userId);
        })
      );
    }

    setOtherCertificationErrors(undefined);
    setAlertMessage(undefined);
    Promise.all(promises).catch((error) => {
      if (
        error.response == null ||
        typeof error.response?.data === "string" ||
        error.response?.data instanceof String
      ) {
        if (error.response == null) {
          setAlertMessage("Failed to connect.");
        } else {
          setAlertMessage(error.response.data);
        }
        setSelectedTab(0);
      } else if (error.response.data.errors) {
        let newErrorObject: any = {};
        Object.keys(error.response.data.errors).forEach((key) => {
          if (error.response.data.errors[key].length > 0) {
            setError(key as keyof ContractorInputs, {
              message: error.response.data.errors[key],
            });
            newErrorObject[key] = { message: error.response.data.errors[key] };
          }
        });

        setOtherCertificationErrors(
          Object.keys(newErrorObject).length > 0 ? newErrorObject : undefined
        );

        // In case when errors are only in Qualification tab open it
        if (
          !error.response.data.errors.hasOwnProperty("FirstName") &&
          !error.response.data.errors.hasOwnProperty("Company") &&
          !error.response.data.errors.hasOwnProperty("UnionMember") &&
          !error.response.data.errors.hasOwnProperty("VaccinationVerified") &&
          !error.response.data.errors.hasOwnProperty("SiteSafetyNumber") &&
          !error.response.data.errors.hasOwnProperty("Title") &&
          !error.response.data.errors.hasOwnProperty("PhoneNumber") &&
          !error.response.data.errors.hasOwnProperty("LastName") &&
          !error.response.data.errors.hasOwnProperty("SecondaryCompany") &&
          !error.response.data.errors.hasOwnProperty("BeaconScannerCard") &&
          !error.response.data.errors.hasOwnProperty("ValidFrom") &&
          !error.response.data.errors.hasOwnProperty("ValidUntil") &&
          (error.response.data.errors.hasOwnProperty("OshaCertificationFrom") ||
            error.response.data.errors.hasOwnProperty("OshaCertificationTo") ||
            error.response.data.errors.hasOwnProperty(
              "VaccinationVerificationDate"
            ) ||
            Object.keys(newErrorObject).length > 0)
        ) {
          setSelectedTab(1);
        } else if (
          Object.keys(error.response.data.errors).length > 0 &&
          selectedTab !== 0
        ) {
          setSelectedTab(0);
        }
      }
    });
  };

  const onSubmitContractor = (formInputData: ContractorInputs) => {
    if (isEditable && selectedEntrances.length === 0) {
      setOpenYesNoDialog({ isOpen: true, formInputs: formInputData });
    } else {
      saveContractorDetail(formInputData);
    }
  };

  return contractorId || newContractorMode ? (
    <form onSubmit={handleSubmit(onSubmitContractor)}>
      <DetailPanelView
        title={data ? `${data?.firstName} ${data?.lastName}` : ""}
        showSaveButton={
          isEditable || canVerifyVaccination || canAssignEntrances
        }
      >
        <Box marginTop={2}>
          <ContractorDetailTabs
            contractorId={contractorId}
            newContractorMode={newContractorMode}
            setNewProfilePhoto={setNewProfilePhoto}
            newCertificationCard={newCertificationCard}
            setNewCertificationCard={setNewCertificationCard}
            register={register}
            control={control}
            data={data}
            errors={errors}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            otherCertificationErrors={otherCertificationErrors}
            alertMessage={alertMessage}
            refreshContractorDetail={refreshContractorDetail}
            viewOnly={!isEditable}
            canVerifyVaccination={canVerifyVaccination}
            canAssignEntrances={canAssignEntrances}
          />
        </Box>
      </DetailPanelView>
      <YesNoDialog
        open={openYesNoDialog.isOpen}
        onConfirm={() => {
          if (openYesNoDialog.formInputs) {
            saveContractorDetail(openYesNoDialog.formInputs);
          }
          setOpenYesNoDialog({ isOpen: false });
        }}
        onCancel={() => setOpenYesNoDialog({ isOpen: false })}
        title="No Site Access"
        message="Contractor doesn't have any access to any site. Do you want to save anyway?"
      />
    </form>
  ) : null;
};

export default memo(ContractorDetailSection);
